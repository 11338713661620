import React from 'react';
import { NextPage } from 'next';
import { Button, Col, Container, Row } from 'reactstrap';
import Head from 'next/head';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import { makePageTitle } from '../../components/utils';
import { Home } from '../../components/Home';

const { pageTitle } = defineMessages({
  pageTitle: {
    id: 'sml.web.404.page_title',
    defaultMessage: 'Not Found 404'
  }
});

export const NotFoundPage: NextPage = React.memo(() => {
  const intl = useIntl();
  return (
    <>
      <Head>
        <title>{makePageTitle(intl.formatMessage(pageTitle))}</title>
      </Head>
      <Home>
        <Container className="text-center py-5">
          <Row>
            <Col className="py-3">
              <h1>
                <FormattedMessage defaultMessage="Not Found 404" id="sml.web.404.title" />
              </h1>
            </Col>
            <Col xs={12}>
              <p>This page could not be found</p>
            </Col>
            <Col xs={12}>
              <Link href="/" passHref>
                <Button color="primary">Go back to home page</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Home>
    </>
  );
});
