import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Footer } from '../layouts/Footer';
import { MainNavbar } from '../layouts/MainNavbar';

export const Home: React.FC = ({ children }) => {
  return (
    <>
      <MainNavbar />
      <Container className="home-container mt-5 rounded-3">
        <Row>
          <Col className="p-5">{children}</Col>
        </Row>
      </Container>
      <Footer hideWaves />
    </>
  );
};
