import { GetStaticProps } from 'next';
import { getIntlProps } from '@moxy/next-intl';
import { NotFoundPage } from '../src/containers/pages/NotFoundPage';

// eslint-disable-next-line import/no-default-export
export default NotFoundPage;

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: await getIntlProps(locale)
});
